import React, { ButtonHTMLAttributes } from "react"
// Regular import crashes the app with "Buffer is not defined" error.
import { useEthers } from "@gorilla-technologies/providers/node_modules/@usedapp/core"
import { Button } from "@gorilla-technologies/corporate-ui"
export type EVMWalletProps = {
  title: string
  onClick?: ButtonHTMLAttributes<HTMLButtonElement>["onClick"]
  disabled?: ButtonHTMLAttributes<HTMLButtonElement>["disabled"]
  kind?: "primary" | "secondary"
  showNearLogo?: boolean
  ui: string
}

export const EVMWallet = ({ title, ui }: EVMWalletProps) => {
  const {
    account,
    activateBrowserWallet,
    switchNetwork,
    deactivate,
    chainId,
    error,
  } = useEthers()

  if (!account) {
    title = "Connect"
  } else {
    title = "Log out"
  }

  return !account ? (
    <Button
      {...{ title }}
      onClick={() => {
        activateBrowserWallet()
      }}
    />
  ) : (
    <Button {...{ title }} onClick={deactivate} />
  )
}

///////////////////////////////////////////////
///////////////////////////////////////////////
///////////////////////////////////////////////

// import { useEthers, useEtherBalance } from "@usedapp/core"
// Regular import crashes the app with "Buffer is not defined" error.
// import { formatEther } from "@ethersproject/units"

/*export const EVMWallet = () => {
  const { account, activate, deactivate } = useEthers()
  const etherBalance = useEtherBalance(account)

  async function onConnect() {
    try {
      const provider = new WalletConnectProvider({
        infuraId: "d8df2cb7844e4a54ab0a782f608749dd",
      })
      await provider.enable()
      await activate(provider)
    } catch (error) {
      console.error(error)
    }
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const ConnectButton = () => (
    <div>
      <button
        type="button"
        // kind={account ? "secondary" : "primary"}
        title="Connect to other Wallets"
        onClick={onConnect}
      />
    </div>
  )

  // eslint-disable-next-line react/no-unstable-nested-components
  const WalletConnectConnect = () => (
    <div>
      {account && (
        <div>
          <div className="inline">
            &nbsp;
            <div className="account">{account}</div>
          </div>
          <br />
        </div>
      )}
      {!account && <ConnectButton />}
      {account && (
        <button
          type="button"
          // kind={account ? "secondary" : "primary"}
          title="Disconnect"
          onClick={deactivate}
        />
      )}
      <br />
    </div>
  )

  return (
    <div>
      <WalletConnectConnect />
      {etherBalance && (
        <div className="balance">
          <br />
          Balance:
          <p className="bold">{formatEther(etherBalance)} ETH</p>
        </div>
      )}
    </div>
  )
} */
