import React, { useState } from "react"
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js"
import { Paragraph } from "../components"

interface PayPalSubscriptionProps {
  onSubscriptionSuccess: (success: boolean) => void
  plan: string
}

export const PayPalSubscription = ({
  onSubscriptionSuccess,
  plan,
}: PayPalSubscriptionProps) => {
  const [{ options }, dispatch] = usePayPalScriptReducer()
  const [currency, setCurrency] = useState(options.currency)

  function selectPlan(plan: string) {
    let planId = ""
    switch (plan) {
      case "bossNear":
        planId = process.env.GATSBY_PAYPAL_PLAN_ID_NEAR_BOSS_SANDBOX as string
        break
      case "tycoonNear":
        planId = process.env.GATSBY_PAYPAL_PLAN_ID_NEAR_TYCOON_SANDBOX as string
        break
      case "bossMultiChain":
        planId = process.env
          .GATSBY_PAYPAL_PLAN_ID_MULTICHAIN_BOSS_SANDBOX as string
        break
      case "tycoonMultiChain":
        planId = process.env
          .GATSBY_PAYPAL_PLAN_ID_MULTICHAIN_TYCOON_SANDBOX as string
        break
      case "entrepreneurMultiChainPlus":
        planId = process.env
          .GATSBY_PAYPAL_PLAN_ID_MULTICHAIN_PLUS_ENTREPRENEUR_SANDBOX as string
        break
      case "bossMultiChainPlus":
        planId = process.env
          .GATSBY_PAYPAL_PLAN_ID_MULTICHAIN_PLUS_BOSS_SANDBOX as string
        break
      case "tycoonMultiChainPlus":
        planId = process.env
          .GATSBY_PAYPAL_PLAN_ID_MULTICHAIN_PLUS_TYCOON_SANDBOX as string
        break
      default:
        planId = process.env.GATSBY_PAYPAL_PLAN_ID_NEAR_BOSS as string
        break
    }
    return planId
  }

  function onCurrencyChange({ target: { value } }: any) {
    setCurrency(value)
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: value,
      },
    })
  }

  const createSubscription = (
    _data: any,
    actions: { subscription: { create: (arg0: { plan_id: string }) => any } }
  ) => {
    return actions.subscription.create({
      plan_id: selectPlan(plan), // Replace with your plan ID
    })
  }

  const onApprove = async (
    data: { subscriptionID?: string | null },
    actions: any
  ) => {
    if (data.subscriptionID) {
      onSubscriptionSuccess(true)
      // Optional message given to subscriber
    } else {
      alert("There was an error creating the subscription.")
    }
  }

  return (
    <>
      {/*<div className="w-5/6 p-2">
        <select value={currency} onChange={onCurrencyChange}>
          <option value="USD">United States dollar</option>
          <option value="EUR">Euro</option>
        </select>
      </div>*/}
      <Paragraph level={3}>
        <div className="m-2 w-5/6">
          <PayPalButtons
            createSubscription={createSubscription}
            onApprove={onApprove}
            style={{
              shape: "rect",
              color: "silver",
              layout: "vertical",
              label: "subscribe",
            }}
          />
        </div>
      </Paragraph>
    </>
  )
}

export default PayPalSubscription
