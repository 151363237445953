import React, { FC } from "react"
import { RouteComponentProps } from "@reach/router"
import { useFormData } from "../form-data-context"
import { Heading, Paragraph, ShopBuilderLayout } from "../components"
import Illustration from "../images/shop-builder/building.svg"
import IllustrationMobile from "../images/shop-builder/building-mobile.svg"

export const Building: FC<RouteComponentProps> = () => {
  const [formData] = useFormData()
  const { shop } = formData

  return (
    <ShopBuilderLayout
      backgroundColor="#E1FBF2"
      decoration={
        <Illustration className="max-h-[200px] max-w-[200px] md:max-h-full md:max-w-full" />
      }
    >
      <div className="flex flex-col gap-6">
        <span className="text-gorilla-green text-base font-bold antialiased">
          You are all set!
        </span>
        <IllustrationMobile className="md:hidden" />
        <Heading level={2}>
          We&apos;re building your{" "}
          <span className="text-gorilla-purple">{shop?.name}</span> shop page
          now
        </Heading>
        <Paragraph level={3}>
          This may take a few minutes and we will send you an email once your
          shop page is ready.
        </Paragraph>
        <Paragraph level={3}>You can close this page now.</Paragraph>
      </div>
    </ShopBuilderLayout>
  )
}
