export async function startBuild(
  email: string,
  account: any,
  plan: string,
  type?: string
): Promise<any> {
  const test = account.slice(3, 10)
  const body = {
    email,
    account: {
      account,
      name: `gorilla-${test}`,
    },
    chains: { moralis: ["eth", "bsc", "polygon", "avalanche", "fantom"] },
    plan,
    type,
  }
  console.log("body", body)
  // Default options are marked with *
  const response = await fetch(process.env.GATSBY_PAGE_BUILDER_API as string, {
    method: "POST",
    body: JSON.stringify(body),
  })

  if (!response.ok) throw Error(response.statusText)
}
