type ChainLogoProps = {
  className?: string
}

export const ChainLogo = ({ className }: ChainLogoProps) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M13.3268 0.817778L9.98454 5.77778C9.75343 6.11556 10.1979 6.52444 10.5179 6.24L13.8068 3.37778C13.8957 3.30667 14.0201 3.36 14.0201 3.48444V12.4267C14.0201 12.5511 13.8601 12.6044 13.789 12.5156L3.83343 0.604445C3.51343 0.213333 3.05121 0 2.53566 0H2.1801C1.25566 0 0.491211 0.764444 0.491211 1.70667V14.2933C0.491211 15.2356 1.25566 16 2.19788 16C2.78454 16 3.33566 15.6978 3.65566 15.1822L6.99788 10.2222C7.22899 9.88444 6.78454 9.47556 6.46454 9.76L3.17566 12.6044C3.08677 12.6756 2.96232 12.6222 2.96232 12.4978V3.57333C2.96232 3.44889 3.12232 3.39556 3.19343 3.48444L13.149 15.3956C13.469 15.7867 13.949 16 14.4468 16H14.8023C15.7445 16 16.509 15.2356 16.509 14.2933V1.70667C16.509 0.764444 15.7445 0 14.8023 0C14.1979 0 13.6468 0.302222 13.3268 0.817778Z"
      fill="inherit"
    />
  </svg>
)
