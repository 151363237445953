import React, { FC, useEffect, useState } from "react"
import { navigate } from "gatsby"
import { RouteComponentProps } from "@reach/router"
import { EVMWallet } from "@gorilla-technologies/corporate-ui"
// Regular import crashes the app with "Buffer is not defined" error.
import {
  Heading,
  Paragraph,
  Link,
  Stepper,
  ShopBuilderLayout,
} from "../components"
import { useFormData } from "../form-data-context"
import { useEthers } from "@gorilla-technologies/providers/node_modules/@usedapp/core"
import Illustration from "../images/shop-builder/login.svg"
import IllustrationMobile from "../images/shop-builder/login-mobile.svg"

const isMainnet = process.env.GATSBY_PAGE_BUILDER_NEAR_NETWORK === "mainnet"

interface Props extends RouteComponentProps {
  // Define any additional props for your component here
}

export const Login: FC<Props> = () => {
  const { account } = useEthers()
  const [formData, setFormData] = useFormData()

  const queryParams = new URLSearchParams(location.search)
  const planParameter = queryParams.get("plan")
  const [steps, setSteps] = useState(
    planParameter?.match(/entrepreneur/g) // && !planParameter?.match(/Plus/gi)
      ? 3
      : 4
  )
  const typeParameter = queryParams.get("type")

  useEffect(() => {
    if (account && planParameter) {
      setFormData({
        ...formData,
        plan: planParameter,
        steps,
        type: typeParameter ? typeParameter : "nft",
      })
      // Redirect user to next page if he is logged in
      navigate("/create-shop/email")
    }
  }, [account, formData, planParameter, setFormData, steps, typeParameter])

  return (
    <ShopBuilderLayout
      backgroundColor="#FDE9E9"
      decoration={
        <Illustration className="max-h-[200px] max-w-[200px] md:max-h-full md:max-w-full" />
      }
    >
      <Stepper steps={steps} activeStep={1} />
      <div className="flex flex-col gap-6">
        <IllustrationMobile className="md:hidden" />
        <Heading level={2}>Login with your Wallet</Heading>
        <Paragraph level={3}>
          To create a shop on GorillaShops you need to have a Wallet. To learn
          more about this read our{" "}
          {/* Todo - write new article that explains what wallet is necessary */}
          <Link href="https://medium.com/@gorillashops/how-to-set-up-your-own-nft-shop-810685bf491d">
            blog post
          </Link>
        </Paragraph>
      </div>
      <EVMWallet title="EVM" key="wallet" ui="customerui" />
      <Link href={isMainnet ? "https://metamask.io/" : "https://metamask.io/"}>
        Get a Wallet
      </Link>
    </ShopBuilderLayout>
  )
}
