import React from "react"
import {
  DAppProvider,
  Config,
  Mainnet,
  Polygon,
  BSC,
  Avalanche,
  Fantom,
} from "@usedapp/core"
export { useEthers, useSigner, useCall, useTokenBalance } from "@usedapp/core"
export const defaultConfig: Config = {
  readOnlyChainId: Mainnet.chainId,

  readOnlyUrls: {
    [Mainnet.chainId]:
      "https://mainnet.infura.io/v3/ec86b2b5c5644c29b07cf35d77d1bd78",

    [Polygon.chainId]:
      "https://polygon-mainnet.infura.io/v3/ec86b2b5c5644c29b07cf35d77d1bd78",
    [Avalanche.chainId]:
      "https://avalanche-mainnet.infura.io/v3/ec86b2b5c5644c29b07cf35d77d1bd78",
    [BSC.chainId]: "https://bsc-dataseed.binance.org",
    [Fantom.chainId]: "https://rpc.ankr.com/fantom",
  },
}

export interface EVMWalletProviderProps {
  children: React.ReactNode
  config: Config
}

export const EVMWalletProvider = ({
  children,
  config = defaultConfig,
}: EVMWalletProviderProps) => (
  <DAppProvider config={config}>{children}</DAppProvider>
)
