import React, { ButtonHTMLAttributes } from "react"
import classNames from "classnames"
import { ChainLogo } from "./ChainLogo"

export interface ButtonProps {
  title: ButtonHTMLAttributes<HTMLButtonElement>["children"]
  onClick?: ButtonHTMLAttributes<HTMLButtonElement>["onClick"]
  disabled?: ButtonHTMLAttributes<HTMLButtonElement>["disabled"]
  kind?: "primary" | "secondary"
  showChainLogo?: boolean
  className?: string
}

export function Button({
  title,
  onClick,
  disabled = false,
  kind = "primary",
  showChainLogo = false,
  className,
}: ButtonProps) {
  const buttonClassName = classNames(
    "inline-flex max-w-xs grow-0 items-center justify-center gap-3 px-4 md:px-5 py-3 font-semibold antialiased leading-6 whitespace-nowrap rounded-md transition text-white",
    kind === "primary" && !disabled && "bg-gorilla-purple gorilla-white",
    kind === "secondary" && !disabled && "bg-white",
    !disabled && "hover:shadow-lg active:shadow-none",
    disabled && "bg-gray-300 cursor-not-allowed",
    className
  )

  return (
    <button
      {...{ onClick, disabled }}
      type="button"
      className={buttonClassName}
    >
      {showChainLogo && <ChainLogo />}
      <div>{title}</div>
    </button>
  )
}
