import React from "react"
import { Router, Redirect, useParams, RouteComponentProps } from "@reach/router"
import { Helmet } from "react-helmet"
import {
  EVMWalletProvider,
  defaultConfig,
} from "@gorilla-technologies/providers"
import Logo from "../../images/logo.svg"
import {
  Login,
  // MuzzToken,
  Email,
  StartBuild,
  Building,
  Subscription,
  NotFound,
} from "../../shop-builder-pages"
import { FormDataProvider } from "../../form-data-context"
import "../../css/create-shop.css"
import { PayPalScriptProvider } from "@paypal/react-paypal-js"
const initialOptions = {
  "client-id": process.env.GATSBY_PAYPAL_CLIENT_ID as string,
  intent: "subscription",
  vault: true,
}

interface Props extends RouteComponentProps {
  // Define any additional props for your component here
}

const CreateShopPage = () => {
  return (
    <EVMWalletProvider config={defaultConfig}>
      <PayPalScriptProvider options={initialOptions}>
        <FormDataProvider>
          <Helmet title="GorillaShops" />
          <div className="bg-pale-blue-gray relative min-h-screen">
            <div className="absolute left-0 right-0 top-10 flex justify-center md:top-8">
              <div className="w-full max-w-screen-2xl px-8 lg:px-14">
                {/* Using an a tag here to trigger an (preventable) page-leaving event */}
                <a href="/">
                  <Logo className="h-8 w-auto md:h-10" />
                </a>
              </div>
            </div>
            <Router basepath="/create-shop" className="min-h-screen">
              <Login path="/login" />
              <Email path="/email" />
              <Subscription path="/subscription" />
              <StartBuild path="/start-build" />
              <Building path="/building" />
              <Redirect from="/" to="/create-shop/login" noThrow />
              <NotFound default />
            </Router>
          </div>
        </FormDataProvider>
      </PayPalScriptProvider>
    </EVMWalletProvider>
  )
}

export default CreateShopPage
