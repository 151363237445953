import React, { FC, useEffect, useState } from "react"
import { navigate } from "gatsby"
import { RouteComponentProps } from "@reach/router"
import {
  Button,
  Heading,
  Paragraph,
  Stepper,
  ShopBuilderLayout,
} from "../components"
import Illustration from "../images/shop-builder/email.svg"
import IllustrationMobile from "../images/shop-builder/email-mobile.svg"
import { PayPalSubscription } from "../helper/paypalSubscription"
import { useFormData } from "../form-data-context"
import { FaCheckCircle } from "react-icons/fa"

export const Subscription: FC<RouteComponentProps> = () => {
  const [subscriptionSuccessfull, setSubscriptionSuccessfull] = useState(false)
  const [formData, setFormData] = useFormData()

  useEffect(() => {
    if (subscriptionSuccessfull) setSubscriptionSuccessfull(true)
  }, [subscriptionSuccessfull])

  const gotToNextStep = () => {
    setFormData({
      ...formData,
      subscribed: subscriptionSuccessfull,
    })

    navigate("/create-shop/start-build")
  }

  const handleSubscriptionSuccess = (
    success: boolean | ((prevState: boolean) => boolean)
  ) => {
    setSubscriptionSuccessfull(success)
  }

  return (
    <ShopBuilderLayout
      backgroundColor="#D4E3FD"
      decoration={
        <Illustration className="max-h-[200px] max-w-[200px] md:max-h-full md:max-w-full" />
      }
    >
      <Stepper steps={formData.steps} activeStep={3} />
      <div className="flex flex-col gap-6">
        <IllustrationMobile className="md:hidden" />
        <Heading level={2}>Set up subscription</Heading>
        <Paragraph level={3}>
          The first two weeks are free. So you can test your Shop without any
          risk. After the two weeks we will charge you 19,99€/month. The
          subscription can be cancelled at any time via PayPal.
        </Paragraph>
      </div>
      <PayPalSubscription
        onSubscriptionSuccess={handleSubscriptionSuccess}
        plan={formData.plan}
      />
      <Button
        title="Next"
        onClick={() => gotToNextStep()}
        disabled={!subscriptionSuccessfull}
      />
    </ShopBuilderLayout>
  )
}
